define('ember-launch-darkly/services/launch-darkly-client-remote', ['exports', 'ember-launch-darkly/lib/null-client'], function (exports, _nullClient) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var Service = Ember.Service;
  var getOwner = Ember.getOwner;
  var RSVP = Ember.RSVP;
  var assert = Ember.assert;
  var warn = Ember.warn;
  var run = Ember.run;
  var Evented = Ember.Evented;
  exports.default = Service.extend(Evented, {
    _client: null,

    init: function init() {
      this._super.apply(this, arguments);
    },
    initialize: function initialize() {
      var user = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var _config = this._config(),
          clientSideId = _config.clientSideId,
          _config$streaming = _config.streaming,
          streaming = _config$streaming === undefined ? false : _config$streaming;

      assert('ENV.launchDarkly.clientSideId must be specified in config/environment.js', clientSideId);

      if (!clientSideId) {
        warn('ENV.launchDarkly.clientSideId not specified. Defaulting all feature flags to "false"', false, { id: 'ember-launch-darkly.client-id-not-specified' });

        this.set('_client', _nullClient.default);

        return RSVP.resolve();
      }

      assert('user.key must be specified in initilize payload', user.key);

      if (!user.key) {
        warn('user.key not specified in initialize payload. Defaulting all feature flags to "false"', false, { id: 'ember-launch-darkly.user-key-not-specified' });

        this.set('_client', _nullClient.default);

        return RSVP.resolve();
      }

      if (!window.LDClient) {
        warn('Launch Darkly JS client not found. Defaulting all feature flags to "false"', false, { id: 'ember-launch-darkly.client-not-found' });

        this.set('_client', _nullClient.default);

        return RSVP.resolve();
      }

      return this._initialize(clientSideId, user, streaming);
    },
    identify: function identify(user) {
      return this._identify(user);
    },
    allFlags: function allFlags() {
      return this.get('_client').allFlags();
    },
    variation: function variation(key, defaultValue) {
      return this.get('_client').variation(key, defaultValue);
    },
    _config: function _config() {
      var appConfig = getOwner(this).resolveRegistration('config:environment');

      return appConfig.launchDarkly || {};
    },
    _initialize: function _initialize(id, user, streamingOptions) {
      var _this = this;

      return new RSVP.Promise(function (resolve, reject) {
        var client = window.LDClient.initialize(id, user);

        client.on('ready', function () {
          _this.set('_client', client);
          run(null, resolve);
        });

        client.on('change', function (settings) {
          Object.keys(settings).forEach(function (key) {
            if (_this._shouldTriggerEvent(key, streamingOptions)) {
              _this.trigger(key);
            }
          });
        });

        run.later(_this, function () {
          if (!_this.get('_client')) {
            run(null, reject);
          }
        }, 10000);
      });
    },
    _identify: function _identify(user) {
      var _this2 = this;

      return new RSVP.Promise(function (resolve) {
        _this2.get('_client').identify(user, null, resolve);
      });
    },
    _shouldTriggerEvent: function _shouldTriggerEvent(key, streamingOptions) {
      if (streamingOptions === true) {
        return true;
      }

      if ((typeof streamingOptions === 'undefined' ? 'undefined' : _typeof(streamingOptions)) === 'object') {
        if (streamingOptions.allExcept && Array.isArray(streamingOptions.allExcept)) {
          return streamingOptions.allExcept.indexOf(key) === -1;
        }

        return streamingOptions[key];
      }

      return false;
    }
  });
});