define('ember-truncate/components/ember-truncate/truncation-noop', ['exports', 'ember-truncate/templates/just-yield'], function (exports, _justYield) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _justYield.default,
    tagName: ''
  });
});