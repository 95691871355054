define('ember-launch-darkly/initializers/launch-darkly', ['exports', 'ember-launch-darkly/services/launch-darkly-client-remote', 'ember-launch-darkly/services/launch-darkly-client-local'], function (exports, _launchDarklyClientRemote, _launchDarklyClientLocal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  var assign = Ember.assign;
  function initialize(application) {
    var appConfig = application.resolveRegistration('config:environment') || {};

    var defaults = {
      local: appConfig.environment !== 'production'
    };

    var config = appConfig.launchDarkly || {};
    config = assign({}, defaults, config);

    var Factory = config.local ? _launchDarklyClientLocal.default : _launchDarklyClientRemote.default;

    application.register('service:launch-darkly-client', Factory);
  }

  exports.default = {
    name: 'launch-darkly',
    initialize: initialize
  };
});