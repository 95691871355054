define('ember-page-title/helpers/page-title', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var guidFor = _ember['default'].guidFor;
  var merge = _ember['default'].merge;
  var getOwner = _ember['default'].getOwner;

  function updateTitle(tokens) {
    set(this, 'title', tokens.toString());
  }

  exports['default'] = _ember['default'].Helper.extend({
    pageTitleList: _ember['default'].inject.service(),
    headData: _ember['default'].inject.service(),

    init: function init() {
      this._super();
      var tokens = get(this, 'pageTitleList');
      tokens.push({ id: guidFor(this) });
    },

    compute: function compute(params, _hash) {
      var tokens = get(this, 'pageTitleList');
      var hash = merge({}, _hash);
      hash.id = guidFor(this);
      hash.title = params.join('');
      tokens.push(hash);
      _ember['default'].run.scheduleOnce('afterRender', get(this, 'headData'), updateTitle, tokens);
      return '';
    },

    destroy: function destroy() {
      var tokens = get(this, 'pageTitleList');
      var id = guidFor(this);
      tokens.remove(id);

      var activeTransition = getOwner(this).lookup('router:main').get('router.activeTransition');
      var headData = get(this, 'headData');
      if (activeTransition) {
        activeTransition.promise['finally'](function () {
          if (headData.isDestroyed) {
            return;
          }
          _ember['default'].run.scheduleOnce('afterRender', headData, updateTitle, tokens);
        });
      } else {
        _ember['default'].run.scheduleOnce('afterRender', headData, updateTitle, tokens);
      }
    }
  });
});