define('npm:clipboard/lib/clipboard-action', function(){ return { 'default': require('clipboard/lib/clipboard-action')};})
define('npm:he', function(){ return { 'default': require('he')};})
define('npm:html-to-text', function(){ return { 'default': require('html-to-text')};})
define('npm:js-vat', function(){ return { 'default': require('js-vat')};})
define('npm:json-stable-stringify', function(){ return { 'default': require('json-stable-stringify')};})
define('npm:kayako-web-push', function(){ return { 'default': require('kayako-web-push')};})
define('npm:lodash', function(){ return { 'default': require('lodash')};})
define('npm:lodash/cloneDeep', function(){ return { 'default': require('lodash/cloneDeep')};})
define('npm:platform', function(){ return { 'default': require('platform')};})
define('npm:string.prototype.trimleft', function(){ return { 'default': require('string.prototype.trimleft')};})
define('npm:twitter-text', function(){ return { 'default': require('twitter-text')};})
define('npm:uuid', function(){ return { 'default': require('uuid')};})
define('npm:uuid/v4', function(){ return { 'default': require('uuid/v4')};})