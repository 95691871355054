define("ember-ace/worker-manifest", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "ace/mode/html_worker": "/assets/ace/worker-html.js",
    "ace/mode/json_worker": "/assets/ace/worker-json.js"
  };
});