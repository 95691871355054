define('ember-launch-darkly/services/launch-darkly-client-local', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var getOwner = Ember.getOwner;
  var RSVP = Ember.RSVP;
  var Evented = Ember.Evented;
  var assign = Ember.assign;
  exports.default = Service.extend(Evented, {
    _allFlags: null,
    _user: null,

    init: function init() {
      this._super.apply(this, arguments);

      var _config = this._config(),
          localFeatureFlags = _config.localFeatureFlags;

      localFeatureFlags = localFeatureFlags || {};

      this._allFlags = localFeatureFlags;
    },
    initialize: function initialize(user) {
      return this.identify(user);
    },
    identify: function identify(user) {
      this._user = user;
      return RSVP.resolve();
    },
    allFlags: function allFlags() {
      return assign({}, this._allFlags);
    },
    variation: function variation(key) {
      var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (this._allFlags.hasOwnProperty(key)) {
        return this._allFlags[key];
      }

      return defaultValue;
    },
    enable: function enable(key) {
      this.setVariation(key, true);
    },
    disable: function disable(key) {
      this.setVariation(key, false);
    },
    setVariation: function setVariation(key, value) {
      this._allFlags[key] = value;
      this.trigger(key);
    },
    user: function user() {
      return this._user;
    },
    _config: function _config() {
      var appConfig = getOwner(this).resolveRegistration('config:environment');
      var config = appConfig.launchDarkly || {};

      return assign({}, config);
    }
  });
});