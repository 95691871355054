define('ember-router-helpers/helpers/url-for', ['exports', 'ember-router-helpers/utils/handle-query-params'], function (exports, _handleQueryParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Helper.extend({
    router: Ember.inject.service(),

    compute: function compute(_params) {
      var _get;

      var params = (0, _handleQueryParams.default)(_params);
      return (_get = this.get('router')).urlFor.apply(_get, _toConsumableArray(params));
    }
  });
});