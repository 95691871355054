define('ember-css-modules/mixins/component-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var getOwner = Ember.getOwner;
  var dasherize = Ember.String.dasherize;
  exports.default = Ember.Mixin.create({
    localClassNames: null,
    localClassNameBindings: null,

    concatenatedProperties: ['localClassNames', 'localClassNameBindings'],

    init: function init() {
      this._super();
      this.classNameBindings = [].concat(_toConsumableArray(this.classNameBindings), _toConsumableArray(localClassNames(this)), _toConsumableArray(localClassNameBindings(this)));
    },


    __styles__: Ember.computed(function () {
      // If styles is an explicitly set hash, defer to it. Otherwise, use the resolver.
      if (this.styles && Object.getPrototypeOf(this.styles) === Object.prototype) {
        return this.styles;
      }

      var key = this._debugContainerKey;
      if (!key) {
        return;
      }

      return getOwner(this).resolveRegistration('styles:components/' + key.substring(key.indexOf(':') + 1));
    })
  });


  function localClassNames(component) {
    return component.localClassNames.map(function (className) {
      return '__styles__.' + className;
    });
  }

  function localClassNameBindings(component) {
    return component.localClassNameBindings.reduce(function (bindings, bindingSource) {
      return bindings.concat(buildBindings(component, bindingSource));
    }, []);
  }

  function buildBindings(component, bindingSource) {
    var styles = component.get('__styles__');

    var _bindingSource$split = bindingSource.split(':'),
        _bindingSource$split2 = _slicedToArray(_bindingSource$split, 3),
        property = _bindingSource$split2[0],
        _bindingSource$split3 = _bindingSource$split2[1],
        trueStyle = _bindingSource$split3 === undefined ? dasherize(property) : _bindingSource$split3,
        falseStyle = _bindingSource$split2[2];

    var trueClasses = (styles[trueStyle] || '').split(/\s+/);
    var falseClasses = (styles[falseStyle] || '').split(/\s+/);
    var bindings = [];

    for (var i = 0, len = Math.max(trueClasses.length, falseClasses.length); i < len; i++) {
      bindings.push(bindingString(property, trueClasses[i], falseClasses[i]));
    }

    return bindings;
  }

  function bindingString(property) {
    var trueClass = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var falseClass = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';

    var binding = property + ':' + (trueClass || '');
    if (falseClass) {
      binding += ':' + falseClass;
    }
    return binding;
  }
});