define('ember-router-helpers/utils/route-params', ['exports', 'ember-router-helpers/utils/handle-query-params'], function (exports, _handleQueryParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var RouteParams = function () {
    function RouteParams(router, params) {
      _classCallCheck(this, RouteParams);

      this._router = router;
      this._inputParams = params;
      this._transitionTo = undefined;
      this._replaceWith = undefined;
      this._processedParams = undefined;

      /*
        We need to opt out of Ember eagerly pulling on the getters defined in this class. This is due to
        watchKeys (https://github.com/emberjs/ember.js/blob/d8880eed573a56c8a9172ef9d2bebcfe8fd25582/packages/ember-metal/lib/watch_key.js#L24)
        getting a property to check for reference counting. Without this, our intentionally lazy getter is evaluated twice
        in a single render.
      */
      var m = Ember.meta(this);
      m.writeWatching('isActive', 1);
      m.writeWatching('url', 1);
      m.writeWatching('transitionTo', 1);
      m.writeWatching('replaceWith', 1);
    }

    _createClass(RouteParams, [{
      key: 'isActive',
      get: function get() {
        var _router;

        return (_router = this._router).isActive.apply(_router, _toConsumableArray(this._params));
      }
    }, {
      key: 'url',
      get: function get() {
        var _router2;

        return (_router2 = this._router).urlFor.apply(_router2, _toConsumableArray(this._params));
      }
    }, {
      key: 'transitionTo',
      get: function get() {
        var _this = this;

        if (this._transitionTo === undefined) {
          this._transitionTo = function (maybeEvent) {
            var _router3;

            if (maybeEvent !== undefined && typeof maybeEvent.preventDefault === 'function') {
              maybeEvent.preventDefault();
            }
            return (_router3 = _this._router).transitionTo.apply(_router3, _toConsumableArray(_this._params));
          };
        }

        return this._transitionTo;
      }
    }, {
      key: 'replaceWith',
      get: function get() {
        var _this2 = this;

        if (this._replaceWith === undefined) {
          this._replaceWith = function () {
            var _router4;

            return (_router4 = _this2._router).replaceWith.apply(_router4, _toConsumableArray(_this2._params));
          };
        }

        return this._replaceWith;
      }
    }, {
      key: '_params',
      get: function get() {
        if (!this._processedParams) {
          this._processedParams = (0, _handleQueryParams.default)(this._inputParams);
        }

        return this._processedParams;
      }
    }]);

    return RouteParams;
  }();

  exports.default = RouteParams;
});