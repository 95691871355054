define('ember-launch-darkly/instance-initializers/expose-local-launch-darkly', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  var assign = Ember.assign;
  function initialize(appInstance) {
    var appConfig = appInstance.resolveRegistration('config:environment') || {};

    var defaults = {
      local: appConfig.environment !== 'production'
    };

    var config = appConfig.launchDarkly || {};
    config = assign({}, defaults, config);

    if (config.local) {
      var client = appInstance.lookup('service:launch-darkly-client');
      window.ld = client;
    }
  }

  exports.default = {
    name: 'expose-local-launch-darkly',
    initialize: initialize
  };
});