define('ember-sticky-element/components/sticky-element', ['exports', 'ember', 'ember-sticky-element/templates/components/sticky-element'], function (exports, _ember, _emberStickyElementTemplatesComponentsStickyElement) {
  var computed = _ember['default'].computed;
  var scheduleOnce = _ember['default'].run.scheduleOnce;
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberStickyElementTemplatesComponentsStickyElement['default'],
    attributeBindings: ['style'],

    /**
     * The offset from the top of the viewport when to start sticking to the top
     *
     * @property top
     * @type {number}
     * @default 0
     * @public
     */
    top: 0,

    /**
     * The offset from the parents bottom edge when to start sticking to the bottom of the parent
     * When `null` (default) sticking to the bottom is disabled. Use 0 or any other appropriate offset to enable it.
     *
     * @property bottom
     * @type {boolean|null}
     * @public
     */
    bottom: null,

    /**
     * @property isSticky
     * @type {boolean}
     * @readOnly
     * @private
     */
    isSticky: computed.or('isStickyTop', 'isStickyBottom').readOnly(),

    /**
     * @property isStickyTop
     * @type {boolean}
     * @readOnly
     * @private
     */
    isStickyTop: computed('parentTop', 'parentBottom', 'isStickyBottom', function () {
      return this.get('parentTop') === 'top' && !this.get('isStickyBottom');
    }).readOnly(),

    /**
     * @property isStickyBottom
     * @type {boolean}
     * @readOnly
     * @private
     */
    isStickyBottom: computed('parentBottom', 'stickToBottom', function () {
      return this.get('parentBottom') !== 'bottom' && this.get('stickToBottom');
    }).readOnly(),

    /**
     * @property parentTop
     * @type {string}
     * @private
     */
    parentTop: 'bottom',

    /**
     * @property parentBottom
     * @type {string}
     * @private
     */
    parentBottom: 'bottom',

    /**
     * @property ownHeight
     * @type {number}
     * @private
     */
    ownHeight: 0,

    /**
     * @property ownWidth
     * @type {number}
     * @private
     */
    ownWidth: 0,

    /**
     * @property stickToBottom
     * @type {boolean}
     * @readOnly
     * @private
     */
    stickToBottom: computed.notEmpty('bottom').readOnly(),

    /**
     * @property windowHeight
     * @type {number}
     * @private
     */
    windowHeight: 0,

    /**
     * @property offsetBottom
     * @type {number}
     * @private
     */
    offsetBottom: computed('top', 'ownHeight', 'bottom', 'windowHeight', function () {
      var _getProperties = this.getProperties('top', 'ownHeight', 'bottom', 'windowHeight');

      var top = _getProperties.top;
      var ownHeight = _getProperties.ownHeight;
      var bottom = _getProperties.bottom;
      var windowHeight = _getProperties.windowHeight;

      return windowHeight - top - ownHeight - bottom;
    }),

    /**
     * Dynamic style for the components element
     *
     * @property style
     * @type {string}
     * @private
     */
    style: computed('isSticky', 'ownHeight', 'ownWidth', function () {
      var height = this.get('ownHeight');
      var width = this.get('ownWidth');
      if (height > 0 && this.get('isSticky')) {
        return _ember['default'].String.htmlSafe('height: ' + height + 'px; width: ' + width + 'px');
      }
    }),

    /**
     * Dynamic style for the sticky container (`position: fixed`)
     *
     * @property containerStyle
     * @type {string}
     * @private
     */
    containerStyle: computed('isStickyTop', 'isStickyBottom', 'top', 'bottom', function () {
      if (this.get('isStickyBottom')) {
        var style = 'position: absolute; bottom: ' + this.get('bottom') + 'px; width: ' + this.get('ownWidth') + 'px';
        return _ember['default'].String.htmlSafe(style);
      }
      if (this.get('isStickyTop')) {
        var style = 'position: fixed; top: ' + this.get('top') + 'px; width: ' + this.get('ownWidth') + 'px';
        return _ember['default'].String.htmlSafe(style);
      }
    }),

    /**
     * @method updateDimension
     * @private
     */
    updateDimension: function updateDimension() {
      this.set('windowHeight', _ember['default'].$(window).height());
      this.set('ownHeight', this.$().height());
      this.set('ownWidth', this.$().width());
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      scheduleOnce('afterRender', this, this.updateDimension);
    },

    actions: {
      parentTopEntered: function parentTopEntered() {
        // console.log('parentTopEntered');
        this.set('parentTop', 'in');
      },
      parentTopExited: function parentTopExited(top) {
        // make sure we captured the right dimensions before getting sticky!
        this.updateDimension();
        // console.log('parentTopExited', top);
        this.set('parentTop', top ? 'top' : 'bottom');
      },
      parentBottomEntered: function parentBottomEntered() {
        // console.log('parentBottomEntered');
        this.set('parentBottom', 'in');
      },
      parentBottomExited: function parentBottomExited(top) {
        // console.log('parentBottomExited', top);
        this.set('parentBottom', top ? 'top' : 'bottom');
      }
    }
  });
});