define("ember-router-helpers/utils/handle-query-params", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (params) {
    var possibleQueryParams = params[params.length - 1];

    if (possibleQueryParams && possibleQueryParams.isQueryParams) {

      // ensure to handle potentially frozen arrays
      params = params.slice();

      params[params.length - 1] = {
        queryParams: possibleQueryParams.values
      };
    }

    return params;
  };
});