define('ember-router-helpers/helpers/transition-to', ['exports', 'ember-router-helpers/utils/handle-query-params'], function (exports, _handleQueryParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Helper.extend({
    router: Ember.inject.service(),

    compute: function compute(_params) {
      var _this = this;

      return function (maybeEvent) {
        var _get;

        if (maybeEvent !== undefined && typeof maybeEvent.preventDefault === 'function') {
          maybeEvent.preventDefault();
        }

        var params = (0, _handleQueryParams.default)(_params);
        return (_get = _this.get('router')).transitionTo.apply(_get, _toConsumableArray(params));
      };
    }
  });
});