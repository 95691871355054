define('ember-smart-banner/utils/local-storage', ['exports'], function (exports) {
  exports.setItem = setItem;
  exports.getItem = getItem;
  var namespace = 'ember-smart-banner';

  function setItem(key, value) {
    localStorage.setItem(_namespacedKey(key), JSON.stringify(value));
  }

  function getItem(key) {
    var result = localStorage.getItem(_namespacedKey(key));
    if (result) {
      return safelyParseJSON(result);
    }
  }

  function _namespacedKey(keyName) {
    return namespace + '.' + keyName;
  }

  function safelyParseJSON(json) {
    var parsed = undefined;

    try {
      parsed = JSON.parse(json);
    } catch (e) {
      // catch error;
    }

    return parsed; // could be undefined
  }
});