define('ember-css-modules/initializers/ember-css-modules', ['exports', 'ember-css-modules/extensions'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {} // Ensure our core extensions are loaded before the app boots
  exports.default = {
    name: 'ember-css-modules',
    initialize: initialize
  };
});