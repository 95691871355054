define("ember-launch-darkly/lib/null-client", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    initialize: function initialize() {},
    identify: function identify(_user, _hash, cb) {
      cb();
    },
    allFlags: function allFlags() {
      return {};
    },
    variation: function variation(_, defaultValue) {
      if (defaultValue !== undefined) {
        return defaultValue;
      }

      return false;
    }
  };
});