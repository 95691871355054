define('ember-diff-attrs/index', ['exports', 'ember-weakmap'], function (exports, _emberWeakmap) {
  var _slice = Array.prototype.slice;

  function isEqual(key, a, b) {
    return a === b;
  }

  exports['default'] = function (keys, hook) {
    var oldValuesMap = new _emberWeakmap['default']();
    var isEqualFunc = isEqual;

    if (typeof keys === 'object') {
      var options = keys;
      keys = options.keys;

      if (options.isEqual) {
        isEqualFunc = options.isEqual;
      }
      if (options.hook) {
        hook = options.hook;
      }
    } else if (arguments.length > 1) {
      keys = [].slice.call(arguments);
      hook = keys.pop();
    } else {
      throw new Error('Invalid `diffAttrs` argument. Expected either one or more strings and a function, or an options hash.');
    }

    return function () {
      var _this = this;

      var changedAttrs = {};
      var oldValues = undefined;
      var isFirstCall = false;

      if (!oldValuesMap.has(this)) {
        isFirstCall = true;
        oldValuesMap.set(this, {});
      }

      oldValues = oldValuesMap.get(this);

      keys.forEach(function (key) {
        var value = _this.get(key);
        if (!isEqualFunc(key, oldValues[key], value)) {
          changedAttrs[key] = [oldValues[key], value];
          oldValues[key] = value;
        }
      });

      hook.apply(this, [isFirstCall ? null : changedAttrs].concat(_slice.call(arguments)));
    };
  };
});