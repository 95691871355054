define('ember-launch-darkly/services/launch-darkly', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var warn = Ember.warn;
  var RSVP = Ember.RSVP;


  var NON_EXISTANT_FLAG_VALUE = 'LD_FLAG_NON_EXISTANT';

  exports.default = Service.extend({
    _seenFlags: null,

    _client: service('launchDarklyClient'),

    init: function init() {
      this._super.apply(this, arguments);
      this._seenFlags = [];
    },
    initialize: function initialize() /*, options = {}*/{
      var user = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      return this.get('_client').initialize(user);
    },
    identify: function identify(user) {
      var _this = this;

      return this.get('_client').identify(user).then(function () {
        return _this._notifyFlagUpdates();
      });
    },
    allFlags: function allFlags() {
      return this.get('_client').allFlags();
    },
    variation: function variation(key) {
      var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var nonExistantFlagValue = NON_EXISTANT_FLAG_VALUE + ': ' + key;
      var value = this.get('_client').variation(key, nonExistantFlagValue);

      if (value === nonExistantFlagValue) {
        warn('Feature flag with key \'' + key + '\' has not been defined. Returning default value of \'' + defaultValue + '\'', false, { id: 'ember-launch-darkly.feature-flag-not-defined' });

        return defaultValue;
      }

      return value;
    },
    _registerComputedProperty: function _registerComputedProperty(key) {
      var _this2 = this;

      Ember.defineProperty(this, key, computed(function () {
        return _this2.variation(key);
      }));
    },
    _registerSubscription: function _registerSubscription(key) {
      var _this3 = this;

      this.get('_client').on(key, function () {
        _this3.notifyPropertyChange(key);
      });
    },
    _notifyFlagUpdates: function _notifyFlagUpdates() {
      var _this4 = this;

      this._seenFlags.forEach(function (key) {
        return _this4.notifyPropertyChange(key);
      });
      return RSVP.resolve();
    },
    unknownProperty: function unknownProperty(key) {
      if (this._seenFlags.indexOf(key) === -1) {
        this._seenFlags.push(key);
      }
      this._registerComputedProperty(key);
      this._registerSubscription(key);
      return this.variation(key);
    }
  });
});