define('ember-truncate/components/ember-truncate/button-toggle', ['exports', 'ember-truncate/templates/components/button-toggle'], function (exports, _buttonToggle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _buttonToggle.default,
    tagName: '',
    DOMClick: function DOMClick() {
      var onClick = this.onClick;
      if (typeof onClick === 'function') {
        onClick();
      }
    }
  });
});